import React, {Suspense, lazy} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const IndexPage = lazy(() => import('./pages/IndexPage'));
const StreamPage = lazy(() => import('./pages/StreamPage'));
const ResearchPage = lazy(() => import('./pages/ResearchPage'));
const VetoPage = lazy(() => import('./pages/VetoPage'));

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/research" component={ResearchPage}/>
        <Route path="/stream" component={StreamPage}/>
        <Route path="/veto" component={VetoPage}/>
        <Route path="/" component={IndexPage}/>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
